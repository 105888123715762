$(function() {

	function textAbstract(el, maxlength, delimiter) {
	  var txt = $(el).text();

	  if (el == null) {
	    return "";
	  }

	  if (txt.length <= maxlength) {
	    return txt;
	  }

	  var t = txt.substring(0, maxlength);
	  var re = /\s+\S*$/;
	  var m = re.exec(t);

	  t = t.substring(0, m.index);

	  return t + "...";
	}


	$('.tag-text').each(function(index, element) {
		$(element).text(textAbstract(element, 147, " "));
	});
});

