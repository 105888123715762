
$(document).ready(function() { 
	$(this).scrollTop(0);

	const resizer = document.querySelector("#resizer");
	const sidebar = document.querySelector("#sidebar");

	if ($('#resizer').length > 0) {

		resizer.addEventListener("mousedown", function(event) {
			document.addEventListener("mousemove", resize, false);
			document.addEventListener("mouseup", function() {
				document.removeEventListener("mousemove", resize, false);
			}, false);
		});

		function resize(e) {
			let size = e.clientX + "px";
			localStorage.setItem('sidebar-width', size);

			sidebar.style.flexBasis = size;
			$('.sidebar-content.left').css('width', size);
			$('.sidebar-content.left').css('max-width', size);

			if ($("#main").outerWidth() < 900) {
				$('.js-generated-titles').addClass("d-none");
				$('.js-generated-titles-sm').removeClass("d-none");
				$('#main-content').css('width', '100%');
				$('.main-inner').addClass('d-block');
				$('.main-inner').removeClass('d-flex');
				$('#mainInnerSidebarTitle').addClass('d-block');
				$('#mainInnerSidebarTitle').removeClass('d-none');
				$('.main-sidebar.right').removeClass('d-block');
				$('#sidebarToggleRight').addClass('d-none');
				$('#sidebarToggleRight').removeClass('d-flex');
				$('.js-generated-titles-sm').addClass('d-flex');
			} else {
				$('.js-generated-titles').removeClass("d-none");
				$('.js-generated-titles-sm').addClass("d-none");
				$('#main-content').css('width', '100%');
				$('.main-inner').removeClass('d-block');
				$('.main-inner').addClass('d-flex');
				$('.main-sidebar.right').addClass('d-block');
				$('#mainInnerSidebarTitle').addClass('d-none');
				$('#mainInnerSidebarTitle').removeClass('d-block');
				$('#sidebarToggleRight').removeClass('d-none');
				$('#sidebarToggleRight').addClass('d-flex');
				$('#sidebarToggleRight').addClass('toggled')
				$('#sidebarToggleRight').attr('data-toggle', 'false');
				$('.js-generated-titles-sm').removeClass('d-flex');
			}
		}

		if(localStorage.getItem('sidebar-width') !== null) {
			sidebar.style.flexBasis = localStorage.getItem('sidebar-width');
		} else {
			sidebar.style.flexBasis = "250px";
			$('.sidebar-content.left').flexBasis = "250px";		
		}

		$('#sidebarToggleLeft').on('click', function() {

			if ($(window).width() > 900) {

				if ($(this).attr("data-toggle") === "false") {
					$(this).addClass('toggled');
					sidebar.style.flexBasis = "0px";
					$('.sidebar-content.left').flexBasis = "0px";
					sidebar.style.minWidth = "0px";
					$('.sidebar-content.left').minWidth = "0px";
					$('#sidebarToggleLeft').attr('data-toggle', 'true');
				} else {
					$(this).removeClass('toggled');
					sidebar.style.flexBasis = localStorage.getItem('sidebar-width');
					$('.sidebar-content.left').flexBasis = localStorage.getItem('sidebar-width');
					sidebar.style.minWidth = "250px";
					sidebar.style.width = "250px";
					$('.sidebar-content.left').minWidth = "250px";
					$(this).attr('data-toggle', 'false');
				}

			} else {

				if ($(this).attr("data-toggle") === "false") {
					$(this).addClass('toggled');
					sidebar.style.flexBasis = "0px";
					$('.sidebar-content.left').flexBasis = "0px";
					sidebar.style.minWidth = "0px";
					$('.sidebar-content.left').minWidth = "0px";
					$('#sidebarToggleLeft').attr('data-toggle', 'true');
				} else {
					$(this).removeClass('toggled');
					sidebar.style.flexBasis = "250px";
					$('.sidebar-content.left').flexBasis = "250px";
					sidebar.style.minWidth = "250px";
					$('.sidebar-content.left').minWidth = "250px";
					$(this).attr('data-toggle', 'false');
				}

			}

			if ($("#main").outerWidth() < 900) {
				$('.js-generated-titles').addClass("d-none");
				$('.js-generated-titles-sm').removeClass("d-none");
				$('#main-content').css('width', '100%');
				$('.main-inner').addClass('d-block');
				$('.main-inner').removeClass('d-flex');
				$('#mainInnerSidebarTitle').addClass('d-block');
				$('#mainInnerSidebarTitle').removeClass('d-none');
				$('.main-sidebar.right').removeClass('d-block');
				$('#sidebarToggleRight').addClass('d-none');
				$('#sidebarToggleRight').removeClass('d-flex');
				$('.js-generated-titles-sm').addClass('d-flex');

			} else {
				$('.js-generated-titles').removeClass("d-none");
				$('.js-generated-titles-sm').addClass("d-none");
				$('#main-content').css('width', '100%');
				$('.main-inner').removeClass('d-block');
				$('.main-inner').addClass('d-flex');
				$('.main-sidebar.right').addClass('d-block');
				$('#mainInnerSidebarTitle').addClass('d-none');
				$('#mainInnerSidebarTitle').removeClass('d-block');
				$('#sidebarToggleRight').removeClass('d-none');
				$('#sidebarToggleRight').addClass('d-flex');
				$('#sidebarToggleRight').addClass('toggled')
				$('#sidebarToggleRight').attr('data-toggle', 'false');
				$('.js-generated-titles-sm').removeClass('d-flex');
			}
		});

		$('#sidebarToggleRight').on('click', function() {

			if ($(window).width() > 900) {

				if ($(this).attr("data-toggle") === "false") {
					$(this).removeClass('toggled');
					$('.main-sidebar.right').addClass('d-none');
					$('.main-sidebar.right').removeClass('d-block');
					$('#sidebarToggleRight').attr('data-toggle', 'true');
				} else {
					$(this).addClass('toggled');
					$('.main-sidebar.right').removeClass('d-none');
					$('.main-sidebar.right').addClass('d-block');
					$(this).attr('data-toggle', 'false');
				}

			}
		});

		$(".tree-item .btn-toggle-collapse").on('click', function() {
			$(this).closest('li').toggleClass('is-expanded')
		});
	}

});


$(document).ready(function() {
	if ($("#main").outerWidth() < 900) {
		$('.js-generated-titles').addClass("d-none");
		$('.js-generated-titles-sm').removeClass("d-none");
		$('#main-content').css('width', '100%');
		$('.main-inner').addClass('d-block');
		$('.main-inner').removeClass('d-flex');
		$('#mainInnerSidebarTitle').addClass('d-block');
		$('.js-generated-titles-sm').addClass('d-flex');
		$('#mainInnerSidebarTitle').removeClass('d-none');
		$('.main-sidebar.right').removeClass('d-block');
		$('#sidebarToggleRight').addClass('d-none');
		$('#sidebarToggleRight').removeClass('d-flex');
		$('.js-generated-titles-sm').addClass('d-flex');
	} else {
		$('.js-generated-titles').removeClass("d-none");
		$('.js-generated-titles-sm').addClass("d-none");
		$('#main-content').css('width', '100%');
		$('.main-inner').removeClass('d-block');
		$('.main-inner').addClass('d-flex');
		$('.main-sidebar.right').addClass('d-block');
		$('#mainInnerSidebarTitle').addClass('d-none');
		$('#mainInnerSidebarTitle').removeClass('d-block');

		$('#sidebarToggleRight').removeClass('d-none');
		$('#sidebarToggleRight').addClass('d-flex');
		$('.js-generated-titles-sm').removeClass('d-flex');
	}
});

$(window).on('resize', function() {


	if ($("#main").outerWidth() < 900) {
		$('.js-generated-titles').addClass("d-none");
		$('.js-generated-titles-sm').removeClass("d-none");
		$('#main-content').css('width', '100%');
		$('.main-inner').addClass('d-block');
		$('.main-inner').removeClass('d-flex');
		$('#mainInnerSidebarTitle').addClass('d-block');
		$('#mainInnerSidebarTitle').removeClass('d-none');
		$('.main-sidebar.right').removeClass('d-block');
		$('#sidebarToggleRight').addClass('d-none');
		$('#sidebarToggleRight').removeClass('d-flex');
		$('.js-generated-titles-sm').addClass('d-flex');
	} else {
		$('.js-generated-titles').removeClass("d-none");
		$('.js-generated-titles-sm').addClass("d-none");
		$('#main-content').css('width', '100%');
		$('.main-inner').removeClass('d-block');
		$('.main-inner').addClass('d-flex');
		$('.main-sidebar.right').addClass('d-block');
		$('#mainInnerSidebarTitle').addClass('d-none');
		$('#mainInnerSidebarTitle').removeClass('d-block');
		$('#sidebarToggleRight').removeClass('d-none');
		$('#sidebarToggleRight').addClass('d-flex');
		$('.js-generated-titles-sm').removeClass('d-flex');
	}

	if( w != $( window ).width() ) {

		if($(window).width() < 900) {
			$('#sidebarToggleLeft').attr('data-toggle', true)
			$('#sidebarToggleLeft').addClass('toggled')
			$('#sidebar').css('min-width', 0);
			$('#sidebar').css('flex-basis', 0);
		} else {
			$('#sidebarToggleLeft').attr('data-toggle', false)
			$('#sidebarToggleLeft').removeClass('toggled')
			$('#sidebar').css('min-width', "250px");
			$('#sidebar').css('flex-basis', localStorage.getItem('sidebar-width'));
		}

		w = $( window ).width();

		delete w;

	}
});


$(document).ready(function() {
	$( "#main-content h2" ).each(function( index ) {
		var x = $(this).toArray();
		var y = $(this).nextUntil('h2', "h3").toArray();
		var _this = $(this);

		$(this).wrap("<div class='section' data-id='#" + $(this).text().toLowerCase().split(' ').join('-') + "'></div>");

		$('.js-generated-titles').prepend('<div class="sidebar-menu-item"> <div class="sidebar-menu-item-header flex-nowrap d-flex justify-content-between align-items-start"> <a href="'+ window.location.href.split("#")[0] + "#" + $(this).text().toLowerCase().split(' ').join('-')  + '"class="dynamic" data-section="#' + $(this).text().toLowerCase().split(' ').join('-') + '">' + $(this).html() + '</a> <button class="btn btn-toggle-collapse" type="button" data-toggle="collapse" data-target="#' + $(this).text().toLowerCase().split(' ').join('-') + '" aria-expanded="false" aria-controls="' + $(this).text().toLowerCase().split(' ').join('-') + '"></button> </div><div class="collapse sidebar-menu-item-body show flex-column-reverse" id="' + $(this).text().toLowerCase().split(' ').join('-') + '">')


		$(y).each(function(index) {
			$("#" + _this.text().toLowerCase().split(' ').join('-')).prepend('<div class="sidebar-menu-item"> <div class="sidebar-menu-item-header"> <a href="'+ window.location.href.split("#")[0] + "#" + $(this).text().toLowerCase().split(' ').join('-')  + '"class="dynamic" data-section="#' + $(this).text().toLowerCase().split(' ').join('-') + '">' +  $(this).html() + '</a> </div> </div> </div> </div>');
		});

	});

	$( "h3" ).each(function( index ) {
		$(this).wrap("<div class='section' data-id='#" + $(this).text().toLowerCase().split(' ').join('-')  + "'></div>");
	});

	var a = $('.js-generated-titles').html();
	$('.js-generated-titles-sm').html(a).find('.sidebar-menu-item-body').removeClass('show');
});

function isScrolledIntoView(elem) {
	var docViewTop = $(window).scrollTop();
	var docViewBottom = docViewTop ;

	var elemTop = $(elem).offset().top;
	var elemBottom = elemTop + $(elem).height();

	return ((docViewTop + 40) >= elemTop);
}

$(window).on('scroll load', function() {
	$(".section").each(function() {
		let section_id;

		if (isScrolledIntoView($(this))) {
			section_id = $(this).attr('data-id').toLowerCase().split(' ').join('-');
			$('[data-section="' + section_id + '"]').addClass('active').parents('.sidebar-menu-item').siblings().find('.dynamic').removeClass('active');
		}
	});
});


var elemLeft;
var break_point;

$(document).ready(function() {
	if ($('.sidebar-content.right').length > 0) {
		elemLeft =  ($('.sidebar-content.right').offset().left / $(window).width()) * 100 ;
	}
});

$(window).on('resize load', function() {
	if ($('.sidebar-content.right').length > 0) {
		elemLeft =  ($('.sidebar-content.right').offset().left / $(window).width()) * 100 ;
	}

	if (elemLeft < 5) {
		elemLeft =  ($('.sidebar-content.right').offset().left / $(window).width()) * 100 ;
	}

});


$(window).on('scroll load', function() {
	var docViewTop = $(window).scrollTop();
	
	if ($('.sidebar-content').length > 0) {
		var elemTop = $('.sidebar-content').offset().top;
	}

	var sidebar_right_width = $('.sidebar-content.right').width();

	if (docViewTop >= elemTop) {
		
		$('.sidebar-content.right').css('left', elemLeft + "%");
		$('.sidebar-content').addClass('fixed-top');
		$('.sidebar-content.right').css('left', 'auto');
		$('.sidebar-content.right').css('right', '30px');

		if(break_point === undefined){ 
			break_point = docViewTop;
		}

		if (break_point > 200) {
			break_point = $('.main-nav').outerHeight() + $('.main-nav-links').outerHeight() + 10 ;
		}

		console.log(break_point)


		if($(window).width() > 900) {

			if(localStorage.getItem('sidebar-width') === undefined || localStorage.getItem('sidebar-width') === null) {

				$('.sidebar-content.left').css('max-width', 250);
				$('.sidebar-content.left').css('width', 250);
			} else {
				$('.sidebar-content.left').css('max-width', localStorage.getItem('sidebar-width'))
			}

		} else {
			$('.sidebar-content.left').css('max-width', "250px")
		}
	}

	if (docViewTop < break_point) {
		$('.sidebar-content').removeClass('fixed-top');
	}

});


var w = 0;



$(document).on("click", ".dynamic",function () {
	var target = "#" + $(this).attr("href").split("#")[1];

	$([document.documentElement, document.body]).animate({
		scrollTop: $("[data-id='" + target + "']").offset().top
	}, 500);
});


