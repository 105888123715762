// import important dependencies
import 'bootstrap';
import './ytb_loader.js';
import './fix_height.js';
import 'intersection-observer';

import './components/tags.js';
import './components/smooth-scroll.js';
import './components/ie.js';
import './components/scrollUp.js';
import './components/cookies.js';
import './components/sidebar.js';




import SimpleLightbox from "simplelightbox";
let lightbox = new SimpleLightbox('.lightbox');


$(function () {
	$('[data-toggle="tooltip"]').tooltip({
		'delay': { "show": 0, "hide": 200 }
	})
})




import lozad from 'lozad'
const observer = lozad(); 
observer.observe();


